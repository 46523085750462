import React from 'react'
import styled from 'styled-components'

import trees from '../images/trees.svg'
import clouds from '../images/clouds-small.svg'

const how = () => {
	return (
		<>
			<TitleBox>
				<h1>personalised consulting</h1>
				<Subtitle>a flexible approach to support your business anywhere through ideation to delivering</Subtitle>
			</TitleBox>
			<Trees><img src={trees}/></Trees>
			<Clouds><img src={clouds}/></Clouds>
			<Background></Background>
		</>
	)
}

const Background = styled.div`
	z-index: -1;
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%), #DAEFED;
`

const TitleBox = styled.div`
	grid-column: 3  / span 4;
	position: relative;
	top: 20vh;

	@media ${props => props.theme.device.tablet} {
		grid-column: 3 / span 3;
	}

	@media ${props => props.theme.device.laptopL} {
		grid-column: 4 / span 2;
	}
`
const Subtitle = styled.h3`
	grid-column: 3  / span 4;


	@media ${props => props.theme.device.tablet} {
		grid-column: 4 / span 3;
	}

	@media ${props => props.theme.device.laptopL} {
		grid-column: 4 / span 2;
	}
`

const Trees = styled.div`
    position: absolute;
    width: 100vw;
	bottom: 0px;
`
const Clouds = styled.div`
    position: absolute;
    width: 100vw;
	bottom: 200px;
`

export default how