import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { Element } from 'react-scroll'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import '../components/styles.css'

import Home from '../components/home-section'
import What from '../components/what-section'
import How from '../components/how-section'
import Why from '../components/why-section'
import Contact from '../components/contact-section'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section name="home-section"><Home/></Section>
    <Section name="why-section"><Why/></Section>
    <Section name="how-section"><How/></Section>
    <Section name="what-section"><What/></Section>
    <Section name="contact-section"><Contact/></Section>
  </Layout>
)

const Section = styled(Element)`
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: min-content;
`
export default IndexPage
