import React from 'react'
import styled from 'styled-components'

import analysis from '../images/analysis.svg'
import dynamic from '../images/dynamic.svg'
import engineering from '../images/engineering.svg'
import web from '../images/web.svg'
import website from '../images/website.svg'

const what = () => {
	return (
		<>
			<Title>everything you need for a bespoke website</Title>
			<FeatureList>
				<Feature>
					<FeatureImg src={website} /> <h3> hand crafted designs</h3></Feature>
				<Feature>
					<FeatureImg src={dynamic} /> <h3>reactive front-end development</h3></Feature>
				<Feature>
					<FeatureImg src={web} /> <h3>custom APIs and integration</h3></Feature>
				<Feature>
					<FeatureImg src={engineering} /> <h3>managed hosting and maintenance</h3></Feature>
				<Feature>
					<FeatureImg src={analysis} /> <h3>tailored SEO and marketing</h3></Feature>
			</FeatureList>
		</>
	)
}

const Title = styled.h1`
	grid-column: 2  / span 4;
	position: relative;
	margin: 20vh 0vh;

	@media ${props => props.theme.device.laptop} {
		grid-column: 2 / span 3;
	}
	@media ${props => props.theme.device.laptopL} {
		grid-column: 2 / span 2;
	}
`

const FeatureList = styled.div`
	grid-column: span 6;
	display: flex;
	justify-content: space-around;
	`
const Feature = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 10em;
	
	`
const FeatureImg = styled.img`
	padding-bottom: 2em;
	width: 100%;
`

export default what