import React from 'react'
import styled from 'styled-components'

import Button from './common/button'

const contact = () => {
	return (
		<>
			<Title>talk with us today</Title>
			<Form name="contact" method="POST" data-netlify="true">
					<Input placeholder="name" type="text" name="name" />
					<Input placeholder="email" type="email" name="email" />
					<Input placeholder="phone" type="text" name="phone" />
					<Input as="textarea" placeholder="message" name="message"></Input>

				<p>
					<Button type="submit">Send</Button>
				</p>
			</Form>
	
		</>
	)
}

const Title = styled.h1`
	text-align: center;
	grid-column: 1  / span 6;
	margin: 10vh 0vh;

	@media ${props => props.theme.device.laptop} {
		grid-column: 3 / span 2;
	}
	@media ${props => props.theme.device.laptopL} {
		grid-column: 3 / span 2;
	}
`

const Form = styled.form`
	grid-column: 3  / span 2;
	display: flex;
	flex-direction: column;
`

const FormField = styled

const Input = styled.input`
	margin: 1rem 0rem;
	padding: 0.7rem;
	border: none;
	background-color: ${props => props.theme.colours.light};
	border-radius: 10px;
	color: ${props => props.inputColor || props.theme.colours.darkGrey};
	&::placeholder {
		color: ${props => props.inputColor || props.theme.colours.darkGrey};
	}
`



export default contact