import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-scroll'
import Button from './common/button'
import { Clouds02 } from './images/clouds-02'

const home = () => {
	return (
		<>
			<Content>
				<Title>the web built for you</Title>
				<HiButton as={Link} to="contact-section" >say hi</HiButton>
			</Content>
			<StyledClouds02/>
			<Background></Background>
		</>
	)
}
const Content = styled.div`
	position: relative;
	top: 50vh;
	z-index: 1;
	grid-column: 2  / span 4;

	@media ${props => props.theme.device.laptopL} {
		grid-column: 2 / span 1;
	}
`
const Title = styled.h1`
`

const HiButton = styled(Button)`
	margin-top: 3rem;
	padding: 0.7rem 3rem;
	display: inline-block;
`

const Background = styled.div`
	z-index: -1;
	height: 100%;
	width: 100%;
	position: absolute;
	background: linear-gradient(180deg, #78CEE1 0%, rgba(255, 255, 255, 0) 100%), #B9F2FF;
`
const StyledClouds02 = styled(Clouds02)`
    position: absolute !important;
    min-width: 100vw;
    min-height: 100vh;
    bottom: 0px;
`
export default home