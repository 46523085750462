import React from 'react'
import styled from 'styled-components'

import { People } from './images/people-1'


const why = () => {
	return (
		<>
			<TitleBox>
				<h1>why you'll love working with us</h1>
				<Subtitle>our work starts with an understanding of your goal</Subtitle>
				<Subtitle>we tailor our services to get your business the solution it needs to maximise its value</Subtitle>
				<Subtitle>we meet needs not covered by out of the box solutions</Subtitle>
			</TitleBox>
			<PeopleImg/>
		</>
	)
}

const TitleBox = styled.div`
	grid-column: 2  / span 4;
	position: relative;
	margin-top: 20vh;

	@media ${props => props.theme.device.tablet} {
		grid-column: 2 / span 4;
	}

	@media ${props => props.theme.device.laptopL} {
		grid-column: 2 / span 3;
	}
`
const Subtitle = styled.h3`
	grid-column: 3  / span 4;


	@media ${props => props.theme.device.tablet} {
		grid-column: 4 / span 3;
	}

	@media ${props => props.theme.device.laptopL} {
		grid-column: 4 / span 2;
	}
`

const PeopleImg = styled(People)`
	grid-column: 2 / span 4;
	z-index: -1;
`



export default why