import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
	cursor: pointer;
	border: none;
	padding: 0.7rem 1rem;
	border-radius: 10px;
	background-color: ${props => props.theme.colours.primary};
	color: ${props => props.theme.colours.primaryLight};
`
export default StyledButton