import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Clouds02 = ({className}) => {
	const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "Clouds-02.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

return (
  <Img className={className} fluid={data.file.childImageSharp.fluid} alt="Couds 02" />
)
}
